import {postRequest} from "../requests/HelperRequest";
import {BallsResponse, BallsSubRequest} from "./models/BallsModel";
import AuthStore from "../auth/store/AuthStore";
import {CheckPromoCodeModel, SubModel} from "./models/SubModel";
import {PromoCodeListDto} from "../promoCodes/models/PromoCodeListDto";

export class BallsRepository {
    loaded = true;
    promoCodeLoaded = true;
    request(dto: BallsSubRequest){
        this.loaded = false;
        postRequest<BallsResponse, BallsSubRequest>('api/Balls/CreateSubs', dto)
            .then((response) => {
                AuthStore.redirect(response.Model!.link);
            })
            .catch((error) => {
                // alert('Возникла ошибка напиши в подержку!')
                console.log(error);
            }).finally(() =>{
            this.loaded = true;
        });
    }
    requestSubs(promoCode: string | undefined){
        this.loaded = false;
        console.log('requestSubs GetSubs: ', promoCode);
        return postRequest<SubModel[], CheckPromoCodeModel>("api/Balls/GetSubs", {promoCode: promoCode ?? ""})
            .catch((error) => {
                // alert('Возникла ошибка напиши в подержку!')
                console.log(error);
            }).finally(() =>{
                this.loaded = true;
            });
    }
    checkPromoCode(promoCode: string | undefined){
        this.promoCodeLoaded = false;
        return postRequest<PromoCodeListDto, CheckPromoCodeModel>("api/Balls/CheckPromoCode", {promoCode: promoCode ?? ""})
            .catch((error) => {
                // alert('Возникла ошибка напиши в подержку!')
                console.log(error);
            }).finally(() =>{
                this.promoCodeLoaded = true;
            });
    }
}