import React from "react";
import './forgotPasswordPage.scss'
import {Button, Input, PasswordInput, TextInput} from "@mantine/core";
import {hasLength, isEmail, useForm} from "@mantine/form";
import {postRequest} from "../../requests/HelperRequest";
import {AuthSignInModel, ForgotPasswordModel} from "../../auth/model/AuthModel";
import {LoadingModel} from "../../requests/LoadingModel";
import AuthStore from "../../auth/store/AuthStore";


export const ForgotPasswordPage = () => {
    let passwordForm = useForm({
        initialValues: {
            newPassword: '',
            email: '',
            loading: false,
        },
        validate: {
            newPassword: hasLength({min: 8}, 'От 8 символов'),
            email: isEmail('Не проходит валидация по e-mail'),
        },
        initialErrors: {
            error: ""
        }
    });
    const setModel = (response: LoadingModel<AuthSignInModel>) => {
        passwordForm.setValues({
            loading: false,
        })
        if (response.Model?.successful) {
            alert('Вы изменили пароль к учетной записи. Пора авторизоваться.');
            AuthStore.redirect("/");
            return;
        }
        passwordForm.setErrors({error: response.Model?.errors});
        console.log('response:', response, passwordForm.errors);
    }
    const updatePassword = (newPassword: string, email: string) => {
        passwordForm.setValues({
            loading: true
        })
        postRequest<AuthSignInModel, ForgotPasswordModel>('api/Auth/ConfirmForgotPassword', {
            newPassword: newPassword,
            email: email,
            code: window.location.pathname.replace('/forgotPassword/', '')
        })
            .then((response) => {
                setModel(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return <div className="forgot-password-page">
        <div className="page">
            <form onSubmit={passwordForm.onSubmit((values, event) =>
                updatePassword(values.newPassword, values.email))}>
                <Input.Wrapper label="E-mail" error={passwordForm.errors["error"]}>
                    <TextInput name='email' placeholder="E-mail" {...passwordForm.getInputProps('email')}/>
                </Input.Wrapper>
                <Input.Wrapper label="Новый пароль">
                    <PasswordInput placeholder="Новый пароль" {...passwordForm.getInputProps('newPassword')}/>
                </Input.Wrapper>
                <Button
                    className="save-button"
                    variant="gradient"
                    type="submit"
                    loading={passwordForm.values.loading}
                    gradient={{from: 'blue', to: 'cyan', deg: 90}}>
                    Изменить пароль
                </Button>
            </form>
        </div>
    </div>
}