export interface TaskLoadingDto {
    id: bigint;
    name: string;
    state: StateTask;
    start?: Date;
    finish?: Date;
    type: TypeTask;
    requestJson?: string;
    resultJson?: string;
    errorMessage?: string;
}

export interface TaskLoadingListDto {
    id: bigint;
    name: string;
    state: StateTask;
    start?: Date;
    finish?: Date;
    type?: TypeTask;
    requestJson?: string;
}

export interface TaskLoadingCreateDto {
    name: string;
    channels: string[];
    type: TypeTask;
    subs?: RangeLong;
    lastMessage?: RangeLong;
}

export interface RangeLong {
    start: number | null;
    finish: number | null;
}

export enum TypeTask {
    Similar = 1,
    LastMessage = 2,
    SimilarAndLastMessage = 3,
    Stat = 4,
    AdsTelegram = 5,
}

export enum StateTask {
    NotStart = 0,
    Loading = 1,
    Finish = 2,
    Error = 3,
    NotEnoughBalls = 4
}

export interface TableColumnDto {
    Chat: ColumnDto,
    Date: string,
    Subs?: number | null,
    ChannelFromFile?: number | null,
    AvgOneAdvertisement?: number | null,
    Comments?: number | null,
    Reactions?: number | null,
    AvgOnePublish?: number | null,
    Description: string,
    CheckBox: boolean;
    Views?: number,

    // ADS telegram
    Word: string,
    TextAds: string,
    DateFirst: string,
    DateLast: string,
    Link: string,
}

export interface ColumnDto {
    ID: number,
    MainUsername: string,
    Photo: string,
    Subscribers: number,
    Title: string,
    About: string
}
