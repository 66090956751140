import {Route, Routes, useLocation} from 'react-router-dom';
import React, {useEffect, Suspense} from 'react';
import {Layout} from "./shared/Layout";
import AuthStore from "./auth/store/AuthStore";
import '@mantine/core/styles.css';
import 'mantine-react-table/styles.css';
import '@mantine/dates/styles.css';
import {Loading} from "./shared/Loading";
import {AuthPage, HomePage} from "./pages";
import {BallsPage} from "./balls/BallsPage";
import {AccountTelegramsPage} from "./pages/accountTelegrams/AccountTelegramsPage";
import {SettingsPage} from "./pages/settingsPage/SettingsPage";
import FooterComponent from './shared/FooterComponent'; // Подключение компонента футера
import './index.css';
import {PromoCodePage} from "./promoCodes/PromoCodePage";
import {ForgotPasswordPage} from "./pages/forgotPassword/ForgotPasswordPage";

export const App = () => {
    const location = useLocation();
    const background = location.state && location.state.background;

    useEffect(() => {
        AuthStore.updateAccount();
    }, []);

    return (
        <>
            {
                !AuthStore.updateAccount() ? (
                    <Routes>
                        <Route path="/forgotPassword*" element={<Suspense fallback={<Loading/>}>
                            <ForgotPasswordPage />
                        </Suspense>}/>
                        <Route path="*" element={<Suspense fallback={<Loading/>}>
                            <AuthPage />
                        </Suspense>}/>
                    </Routes>
                ) : (
                    <div id="app-root">
                        <Layout>
                            <Routes location={background || location}>
                                <Route path="/" element={<Suspense fallback={<Loading/>}>
                                    <HomePage/>
                                </Suspense>}/>
                                <Route path="/account-telegram" element={<Suspense fallback={<Loading/>}>
                                    <AccountTelegramsPage/>
                                </Suspense>}/>
                                <Route path="/balls" element={<Suspense fallback={<Loading/>}>
                                    <BallsPage/>
                                </Suspense>}/>
                                <Route path="/settings" element={<Suspense fallback={<Loading/>}>
                                    <SettingsPage/>
                                </Suspense>}/>
                                {
                                    AuthStore.admin &&
                                    <Route path="/promocodes" element={<Suspense fallback={<Loading/>}>
                                        <PromoCodePage/>
                                    </Suspense>}/>
                                }
                            </Routes>
                            <FooterComponent /> {/* Добавляем футер сюда */}
                        </Layout>
                    </div>
                )
            }
        </>
    );
};
