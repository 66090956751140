import React, {useEffect, useState} from 'react';
import './ballsPage.scss';
import {BallsModel} from "./models/BallsModel";
import {getRequest} from "../requests/HelperRequest";
import {Loading} from "../shared/Loading";
import {formatDateTime} from "../helper/Formatting";
import {Group, Card, Button, Text, Title, TextInput} from '@mantine/core';
import {BallsRepository} from "./BallsRepository";
import {SubModel} from "./models/SubModel";


export const BallsPage = () => {
    let [balls, setBalls] = useState<BallsModel[] | undefined>(undefined);
    let [subs, setSubs] = useState<SubModel[] | undefined>(undefined);
    let [promoCode, setPromoCode] = useState<string | undefined>(undefined);
    let [hasPromoCode, setHasPromoCode] = useState<boolean>(false);
    let repository = new BallsRepository();
    const request = () => {
        setBalls(undefined);
        getRequest<BallsModel[]>("api/Balls")
            .then(x => {
                if (!!x.Model) {
                    setBalls(x.Model)
                } else if (!!x.ErrorM) {
                    alert(x.Error)
                }
            });
    }
    const requestSub = () => {
        setSubs(undefined);
        repository.requestSubs(hasPromoCode ? promoCode : "")
            .then(x => {
                if (x) {
                    if (!!x.Model) {
                        setSubs(x.Model)
                    } else if (!!x.ErrorM) {
                        alert(x.Error)
                    }
                }
            })
    }

    useEffect(() => {
        request();
        requestSub()
    }, [])

    useEffect(() => {
        requestSub()
    }, [hasPromoCode])

    const buyBalls = (id: string, price: number) => {
        repository.request({amount: price, promoCode: promoCode ?? "", id: id});
    }

    const checkPromoCode = () => {
        repository.checkPromoCode(promoCode)
            .then(x => {
                setHasPromoCode(true);
            }).catch(() => {
            alert('Промокод не найден')
        });
    }

    const setEmptyPromoCode = () => {
        setHasPromoCode(false);
    }

    return (
        <div className="balls-page">
            <div className="purchase">
                <Title order={2} className="purchase-title">Купить баллы</Title>
                <Card shadow="sm" padding="lg" radius="md" withBorder>
                    <div>
                        <TextInput
                            style={{width: '100%'}}
                            label="Введите Промокод"
                            placeholder="Введите Промокод"
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.currentTarget.value)}
                        />
                        <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                            {
                                !hasPromoCode &&
                                <Button onClick={checkPromoCode}
                                        className="balls_button">Подтвердить промокод</Button>
                            }
                            {
                                hasPromoCode &&
                                <Button onClick={setEmptyPromoCode}
                                        className="balls_button">Отменить промокод</Button>
                            }
                        </div>
                    </div>
                    <Group className="content_group">
                        {subs?.sort((x, y) => x.amount - y.amount).map((pkg, index) => (
                            <div key={pkg.id} className="package-option">
                                <Text className="package-name">{pkg.name}</Text>
                                {
                                    !hasPromoCode &&
                                    <>
                                        <Text>{pkg.amount} рублей</Text>
                                        <Text>{pkg.balls} баллов
                                            ({(pkg.amount / pkg.balls).toFixed(0)}р/балл)</Text>
                                    </>
                                }
                                {
                                    hasPromoCode &&
                                    <>
                                        <Text>{pkg.amount} рублей </Text>
                                        <Text>{pkg.currentBalls} баллов с промокодом
                                            ({(pkg.currentAmount / pkg.currentBalls!).toFixed(1)}р/балл)</Text>
                                    </>
                                }
                                <Button onClick={() => buyBalls(pkg.id, pkg.currentAmount)}
                                        className="balls_button">Купить</Button>
                            </div>
                        ))}
                    </Group>
                </Card>
            </div>
            <div className="history">
                <h1>История баллов</h1>
                {!balls && <Loading/>}
                {balls?.map((x) => (
                    <Card shadow="sm" padding="lg" radius="md" withBorder className="page" key={x.id}>
                        <div>
                            {x.balls > 0 && <div>Добавили баллов: {x.balls}</div>}
                            {x.balls <= 0 && <div>Потратили баллов: {-x.balls}</div>}
                        </div>
                        <div>Дата: {formatDateTime(x.dateWriteOff)}</div>
                    </Card>
                ))}
            </div>
        </div>
    );
};
