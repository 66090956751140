import {Button, Card, Input, PasswordInput, Text, TextInput} from "@mantine/core";
import AuthStore from "../store/AuthStore";
import React, {useState} from "react";
import {hasLength, useForm} from "@mantine/form";
import {useDisclosure} from "@mantine/hooks";


interface Props {
    clickRegistration: () => void;
    clickForgotPassword: () => void;
}

export const AuthorizationComponent = (props: Props) => {
    const formAuth = useForm({
        // mode: 'controlled',
        initialValues: {
            login: '',
            password: '',
        },
        validate: {
            login: hasLength({min: 2}, 'От 6 символов'),
        },
    });
    const [visible, {toggle}] = useDisclosure(false);
    const [error, setError] = useState<string | null>();

    const auth = (loginEmail: string, password: string) => {
        AuthStore.authorization({
            loginEmail: loginEmail,
            password: password,
        }).then(x => {
            if (!x.successful){
                setError(x.error)
            }
        })
    }

    return <Card className="auth-card" shadow="sm" padding="lg" radius="md" withBorder>
        <form onSubmit={formAuth.onSubmit((values, event) =>
            auth(values.login, values.password))}>
            <div className="title">Авторизация</div>
            <Input.Wrapper error={error} label="Логин или e-mail">
                <TextInput placeholder="Логин или e-mail" {...formAuth.getInputProps('login')}/>
            </Input.Wrapper>
            <PasswordInput
                label="Пароль"
                {...formAuth.getInputProps('password')}
                visible={visible}
                onVisibilityChange={toggle}/>
            <Text onClick={props.clickForgotPassword} className="link-page">Забыли пароль?</Text>
            <Button
                className="authorization-button"
                variant="gradient"
                type="submit"
                loading={AuthStore.isAuth}
                gradient={{from: 'blue', to: 'cyan', deg: 90}}>
                Авторизоваться
            </Button>
            <Text onClick={props.clickRegistration} className="link-page">Зарегистрироваться</Text>
        </form>
    </Card>
}