import React, { useState } from 'react';
import { CombinedComponent } from "../taskLoading/CombinedComponent";
import './homePage.scss';

export const HomePage = () => {

    return (
        <div className="homePage">
            <CombinedComponent />
        </div>
    );
};
