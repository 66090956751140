import React, {useState} from 'react';
import './authComponent.scss'
import {RegistrationComponent} from "./components/RegistrationComponent";
import {ForgotPasswordComponent} from "./components/ForgotPasswordComponent";
import {AuthorizationComponent} from "./components/AuthorizationComponent";
import {ConfirmEmailComponent} from "./components/ConfirmEmailComponent";

export enum AuthType {
    'auth',
    'registration',
    'forgotPassword',
    'confirmEmail' = 3
}

export const AuthComponent = () => {
    const [type, setType]
        = useState(AuthType.auth);

    const clickForgotPassword = () => {
        setType(AuthType.forgotPassword);
    }
    const clickAuth = () => {
        setType(AuthType.auth);
    }
    const clickRegistration = () => {
        setType(AuthType.registration);
    }
    const confirmEmail = () => {
        setType(AuthType.confirmEmail);
    }

    return (
        <div className="auth-component">
            {
                type === AuthType.auth &&
                <AuthorizationComponent clickForgotPassword={clickForgotPassword}
                                        clickRegistration={clickRegistration} />
            }
            {
                type === AuthType.forgotPassword &&
                <ForgotPasswordComponent clickAuth={clickAuth} />
            }
            {
                type === AuthType.registration &&
                <RegistrationComponent clickAuth={clickAuth} confirmEmail={confirmEmail}/>
            }
            {
                type === AuthType.confirmEmail &&
                <ConfirmEmailComponent />
            }
        </div>
    );
};
