import {getRequest, postRequest, postRequestRequest} from "../../requests/HelperRequest";
import {
    ChangeEmailDto,
    BaseResultDto,
    ChangeNameDto,
    ChangePasswordDto,
    SettingsUserDto
} from "./models/SettingsUserDto";
import {LoadingModel} from "../../requests/LoadingModel";
import {hasLength, isEmail, useForm} from "@mantine/form";
import {makeAutoObservable} from "mobx";
import {errorText} from "../../helper/Errors";


export class SettingsStore {
    formChangeName = useForm({
        initialValues: {
            firstName: '',
            surName: '',
            loading: false,
        },
    });
    formChangePassword = useForm({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            loading: false,
        },
        validate: {
            newPassword: hasLength({min: 8}, 'От 8 символов')
        },
        initialErrors:{
            error: ""
        }
    });
    formChangeEmail = useForm({
        initialValues: {
            newEmail: '',
            loading: false,
        },
        initialErrors:{
            error: ""
        },
        validate:{
            newEmail: isEmail('Не проходит валидация по e-mail'),
        }
    });
    formEmailConfirmed = useForm({
        initialValues: {
            confirmedEmail: false,
            loading: false,
        },
    });
    model: LoadingModel<SettingsUserDto> = new LoadingModel<SettingsUserDto>()

    init() {
        this.request();
    }
    constructor() {
        makeAutoObservable(this, {}, {autoBind: true});
    }

    setModel(model: LoadingModel<SettingsUserDto>) {
        this.model = model;
        this.formChangeName.setValues({
            firstName: this.model.Model!.firstName,
            surName: this.model.Model!.surName
        });
        this.formEmailConfirmed.setValues({
            confirmedEmail: this.model.Model!.emailConfirmed
        })
    }

    request() {
        this.model.IsLoaded = true;
        getRequest<SettingsUserDto>('api/Settings/Get')
            .then((response) => {
                this.setModel(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    confirmEmail() {
        this.formEmailConfirmed.setValues({
            loading: true
        })
        postRequestRequest<{}>('api/Settings/ConfirmEmail', {})
            .then((response) => {
                alert('Сообщение отправленно вам на почту. Пройдите по ссылке в письме');
            })
            .catch((error) => {
                console.log(error);
            }).finally(()=>{
            this.formEmailConfirmed.setValues({
                loading: false
            })
        });
    }

    updateName(firstname: string, surname: string) {
        let dto: ChangeNameDto = {
            firstName: firstname,
            surName: surname
        }
        this.formChangeName.setValues({loading: true})
        postRequestRequest<ChangeNameDto>('api/Settings/ChangeName', dto)
            .then((response) => {
                this.request();
                this.formChangePassword.setValues({
                    oldPassword: "",
                    newPassword: "",
                })
            })
            .catch((error) => {
                console.log(error);
            }).finally(() =>{
                this.formChangeName.setValues({loading: false})
            });
    }

    updatePassword(oldPassword: string, newPassword: string) {
        let dto: ChangePasswordDto = {
            oldPassword: oldPassword,
            newPassword: newPassword
        }
        this.formChangePassword.setValues({loading: true})
        postRequest<BaseResultDto, ChangePasswordDto>('api/Settings/ChangePassword', dto)
            .then((response) => {
                this.formChangePassword.setValues({loading: false});
                if (response.Model?.Successfully === true){
                    this.request();
                    return;
                }
                if (!!response.Model?.errorCodes){
                    this.formChangePassword.setErrors({error: response.Model?.errorCodes.map(x => errorText(x)).join(", ")});
                }
            })
            .catch((error) => {
                this.formChangePassword.setValues({loading: false})
            });
    }

    updateEmail(newEmail: string) {
        let dto: ChangeEmailDto = {
            newEmail: newEmail
        }
        this.formChangeEmail.setValues({loading: true})
        this.formChangeEmail.clearErrors();
        postRequest<BaseResultDto, ChangeEmailDto>('api/Settings/ChangeEmail', dto)
            .then((response) => {
                this.formChangeEmail.setValues({loading: false});
                if (response.Model?.Successfully === true){
                    this.request();
                    return;
                }
                if (!!response.Model?.errorCodes){
                    this.formChangeEmail.setErrors({error: response.Model?.errorCodes.map(x => errorText(x)).join(", ")});
                }
            })
            .catch((error) => {
                this.formChangeEmail.setValues({loading: false})
                console.log(error);
            });
    }
}