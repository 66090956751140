import React from 'react';
import './FooterComponent.scss'; // Подключение стилей

const FooterComponent = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-column">
          <h3>Ресурсы</h3>
          <ul>
            <li><a href="https://t.me/intgpars">Канал в Телеграм</a></li>
            <li><a href="https://telepars.ru/blog">Блог</a></li>
            <li><a href="https://telepars.ru/policy">Политика конфиденциальности</a></li>
            <li><a href="/terms-of-use">Условия использования</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Продукты</h3>
          <ul>
            <li><a href="https://chromewebstore.google.com/detail/link-adder/ddapbcogfkcajjedbgfihfmkjlppejmm">Расширение в Chrome</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Компания</h3>
          <ul>
            <li><a href="https://telepars.ru/telus">Контакты</a></li>
            <li><a href="https://telepars.ru/about">О нас</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
