import { StateTask, TaskLoadingListDto } from "./models/TaskLoadingDto";
import { Card, Group } from "@mantine/core";
import {
    IconAtOff, IconBan,
    IconCheck,
    IconLoader, IconRefresh
} from '@tabler/icons-react';
import './taskLoadingRowComponent.scss';
import { formatDateTimeWithEmpty } from "../helper/Formatting";

export const TaskLoadingRowComponent = (props: { model: TaskLoadingListDto, open: (id: bigint) => void, repeatTask: (id: bigint) => void }) => {
    const getTitle = () => {
        if (props.model.name)
            return props.model.name
        const json = JSON.parse(props.model.requestJson!);
        const channels = json['Channels'];
        const channelsString = channels.join(", ");
        return channelsString.length > 40 ? channelsString.substring(0, 40) + "..." : channelsString;
    }

    const getModelType = () => {
        switch (props.model.type) {
            case 1: return "Похожие каналы";
            case 2: return "Анализ каналов";
            case 3: return "Похожие каналы и последние сообщения";
            case 4: return "Анализ";
            case 5: return "Телеграм реклама";
            default: return "Дописать тип";
        }
    }

    const onOpen = () => {
        props.open(props.model.id);
    }

    const repeatTask=() =>{
        props.repeatTask(props.model.id)
    }

    return (
        <Card className="task-loading-row" shadow="sm" padding="lg" radius="md" withBorder >
            <Card.Section>
                <Group className="header-body">
                    <div className="title" onClick={onOpen}>Задача: <span className="title_span" title={getTitle()}>{getTitle()}</span></div>
                    <div className="status-icon">
                        {(props.model?.state === StateTask.Loading || props.model?.state === StateTask.NotStart) &&
                            <IconLoader color="blue" size={24} />
                        }
                        {
                            props.model?.state === StateTask.Finish &&
                            <IconCheck color="green" size={19} />
                        }
                        {props.model?.state === StateTask.NotEnoughBalls &&
                            <IconAtOff color="red" size={19} />
                        }
                        {props.model?.state === StateTask.Error &&
                            <IconBan color="red" size={19} />
                        }
                        {
                            (props.model?.state === StateTask.Finish || props.model?.state === StateTask.NotEnoughBalls || props.model?.state === StateTask.Error)  &&
                            <IconRefresh color="blue" onClick={repeatTask} size={19} />
                        }
                    </div>
                </Group>
            </Card.Section>
            <div className="task-loading-component" onClick={onOpen}>
                <div className="model-type">{getModelType()}</div>
                <div className="finish-time">Окончание: <span>{formatDateTimeWithEmpty((props.model?.finish?.toString() ?? undefined), 'Ещё не закончилось')}</span></div>
            </div>
        </Card>
    );
}
