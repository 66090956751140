import {Button} from "@mantine/core";
import type {MantineStyleProp} from "@mantine/core/lib/core/Box/Box.types";


interface Props {
    style?: MantineStyleProp;
    children?: React.ReactNode;
    onClick?: () => void;
    name?:string;
    className?:string;
}

export function CustomButton(props: Props) {
    return <Button name={props.name} className={props.className} variant="gradient" style={props.style} onClick={props.onClick}>{props.children}</Button>
}