import React, { ChangeEvent, Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Button, Table, Textarea, TextInput } from '@mantine/core';
import './CombinedComponent.scss';
import { TaskLoadingCreateDto, TableColumnDto, TaskLoadingDto, TypeTask } from './models/TaskLoadingDto';
import TaskLoadingStore from './TaskLoadingStore';
import { TaskLoadingRowComponent } from './TaskLoadingRowComponent';
import {getRequest, getRequestResponse} from '../requests/HelperRequest';
import { Loading } from '../shared/Loading';
import { observer } from 'mobx-react';
import TaskLoadingModalTable from './components/TaskLoadingModalTable';
import { splitChannel } from '../helper/Formatting';
import { Tooltip } from '@mantine/core';
import AuthStore from "../auth/store/AuthStore";

interface CreateProps {
    channel: string,
    setChannel: Dispatch<SetStateAction<string>>,
}

interface TableProps {
    addChannels: (channels: string[]) => void
}

const TaskLoadingCreateComponent = observer((props: CreateProps) => {
    let [taskName, setTaskName] = useState<string>('');
    let [openSection, setOpenSection] = useState<string | null>(null);
    let [errorMessage, setErrorMessage] = useState('');
    

    const requestCreate = (model: TaskLoadingCreateDto) => {
        setErrorMessage('');
        if (props.channel === '' || listChannels().length === 0 || taskName.trim() === '') {
            setErrorMessage('Заполните имя задачи и список каналов');
            return;
        }
        const modelRequest: TaskLoadingCreateDto = {
            ...model,
            name: taskName,
        };
        TaskLoadingStore.createRequest(modelRequest)
            .then(x => {
                if (x.status === 401){
                    x.json().then(e => alert(e.message));
                }
                if (x.status === 200){
                    props.setChannel('');
                    setTaskName('');
                }
            });
    };

    const searchSimilarChannelsRequest = () => {
        let channels = listChannels();

        setErrorMessage('');
        if (listChannels().length > 200) {
            setErrorMessage('Можно не больше 200 каналов.');
            return;
        }
        const model: TaskLoadingCreateDto = {
            name: taskName,
            channels: channels,
            type: 1,
        };
        requestCreate(model);
    };

    const adsTelegramRequest = () => {
        const model: TaskLoadingCreateDto = {
            name: taskName,
            channels: listChannels(),
            type: 5,
        };
        requestCreate(model);
    };

    const statisticsRequest = () => {
        const model: TaskLoadingCreateDto = {
            name: taskName,
            channels: listChannels(),
            type: 4,
        };
        requestCreate(model);
    };

    const changeChannels = (ev: ChangeEvent<HTMLTextAreaElement>) => {
        props.setChannel(ev.currentTarget.value);
    };

    const handleFileUpload = (ev: ChangeEvent<HTMLInputElement>) => {
        const file = ev.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const text = e.target?.result;
                if (text) {
                    props.setChannel(text as string);
                }
            };
            reader.readAsText(file);
        }
    };

    function listChannels() {
        return props.channel.split(splitChannel).filter(x => x !== '');
    }

    const removeChannel = (key: string, index: number) => {
        let res = listChannels();
        res.splice(index, 1);
        props.setChannel(res.join(splitChannel));
    };

    return (
        <div className="create-loading">
            <div className="textarea-container">
                <TextInput
                    style={{ width: '100%' }}
                    label="Имя задачи"
                    placeholder="Введите имя задачи"
                    value={taskName}
                    onChange={(e) => setTaskName(e.currentTarget.value)}
                />
                <Textarea
                    style={{ width: '100%' }}
                    label="Список каналов"
                    placeholder="Начинайте вводить каналы, каждый канал с новой строчки. Не пишите закрытые каналы, потому что Telegram позволяет находить похожие каналы только у открытых каналов."
                    value={props.channel}
                    onChange={changeChannels}
                    rows={4}
                />
                <div className="file-upload-container">
                    <input
                        type="file"
                        id="file-upload"
                        className="file-upload"
                        accept=".txt"
                        onChange={handleFileUpload}
                    />
                    <label htmlFor="file-upload" className="file-upload-label">
                        <Tooltip label="Загрузить файл">
                            <img src="/file.png" alt="Выберите файл" className="file-upload-image" />
                        </Tooltip>
                    </label>

                </div>
            </div>
            <div className="buttons-container">
                <button className="custom-button" onClick={statisticsRequest}>Анализировать</button>
                <button className="custom-button" onClick={searchSimilarChannelsRequest}>Найти похожие каналы</button>
                <button className="custom-button" onClick={adsTelegramRequest}>Телеграм реклама</button>
            </div>
            <div className="info-text">
                <p><strong>FAQ</strong></p>
                <div>
                    <a href="#" onClick={() => setOpenSection(openSection === 'similarChannels' ? null : 'similarChannels')}>Что такое похожие каналы?</a>
                    {openSection === 'similarChannels' && (
                        <div className="faq-details">
                            <p><strong>Похожие каналы в Telegram</strong> — это инструмент, позволяющий пользователям находить каналы с наибольшей пересекающейся аудиторией с исходным каналом. Этот список формируется на основе анализа количества общих подписчиков между каналами. Чем выше канал в списке похожих, тем больше подписчиков из исходного канала присутствует в этом канале.</p>
                            <p>Наш сервис предоставляет возможность поиска каналов, в которых больше всего подписчиков из указанного исходного канала. Например, если вы укажете десять каналов, связанных с маркетингом, система сможет найти несколько сотен каналов, также посвященных маркетингу, так как их аудитория будет пересекаться с исходными каналами.</p>
                            <p>Важно отметить, что не у всех каналов есть похожие каналы. В таких случаях, анализ будет невозможен. Наш сервис использует только внутренние инструменты Telegram и предоставляет те похожие каналы, которые сам Telegram может предложить.</p>
                        </div>
                    )}
                </div>
                <div>
                    <a href="#" onClick={() => setOpenSection(openSection === 'channelAnalysis' ? null : 'channelAnalysis')}>Что такое Анализ каналов?</a>
                    {openSection === 'channelAnalysis' && (
                        <div className="faq-details">
                            <p><strong>Анализ каналов</strong> - это сбор информации по каждому каналу, который вы предоставляете.</p>
                            <p>Сервис предоставит следующую информацию: Имя канала, описание канала, количество подписчиков, средний охват постов за длительное время, средний охват рекламных постов.</p>
                            <p>С помощью этого анализа вы сможете быстро отобрать каналы из большого списка каналов. Например, найти те, у которых охват не менее 1000 подписчиков, а в описании указан контакт администратора.</p>
                        </div>
                    )}
                </div>
                <div>
                    <a href="#" onClick={() => setOpenSection(openSection === 'costDetails' ? null : 'costDetails')}>Стоимость парсинга</a>
                    {openSection === 'costDetails' && (
                        <div className="cost-details">
                            <p>Стоимость поиска похожих каналов у одного канала составляет 1 балл.</p>
                            <p>Стоимость анализа одного канала составляет 0.1 балл</p>
                        </div>
                    )}
                </div>
            </div>
            <div style={{ color: 'red' }}>
                {errorMessage}
            </div>

            <div style={{ height: '294px', overflow: 'auto' }}>
                <Table>
                    <Table.Tbody>
                        {listChannels().map((key, index) => (
                            <Table.Tr key={index}>
                                <Table.Td>{index + 1}</Table.Td>
                                <Table.Td>{key}</Table.Td>
                                <Table.Td>
                                    <Button name={key} onClick={() => removeChannel(key, index)}>Удалить</Button>
                                </Table.Td>
                            </Table.Tr>
                        ))}
                    </Table.Tbody>
                </Table>
            </div>
        </div>
    );
});

export const TaskLoadingTableComponent = observer((props: TableProps) => {
    let [modal, setModal] = useState(false);
    let [columns, setColumns] = useState<TableColumnDto[]>();
    let [taskLoading, setTaskLoading] = useState<TaskLoadingDto>();

    useEffect(() => {
        TaskLoadingStore.request();
    }, [])

    useEffect(() => {
        const timer = setInterval(() => {
            TaskLoadingStore.request();
        }, 2000);
        return () => clearInterval(timer);
    }, []);

    const close = () => {
        setModal(false)
    }
    const open = (id: bigint) => {
        setModal(true)
        setColumns([]);
        loadingDescriptionModel(id);
    }
    const repeatTask = (id: bigint) => {
        getRequestResponse('api/TaskLoading/'+id+'/repeatTask')
            .then(x => {
                TaskLoadingStore.request();
            })
    }

    const loadingDescriptionModel = (id: bigint) => {
        getRequest<TaskLoadingDto>('api/TaskLoading/Get?id=' + id)
            .then(x => {
                setTaskLoading(x.Model!)
                if (x.Model?.resultJson !== '{}' && x.Model?.resultJson !== '[]') {
                    setColumns(JSON.parse(x.Model?.resultJson ?? "[]"));
                }
            });
    }

    const addArrayChannels = (v: ({ index: number[] })) => {
        if (taskLoading!.type === TypeTask.Stat){
            props.addChannels(columns?.filter((x, y) =>
                v.index.includes(y))?.map((e) => e.ChannelFromFile) ?? [])

        } else {
            props.addChannels(columns?.filter((x, y) =>
                v.index.includes(y))?.map((e) => e.Chat.MainUsername) ?? [])
        }
        close();
    }

    return <div className="table-loading">
        {
            modal &&
            <TaskLoadingModalTable columns={columns}
                                   modal={modal}
                                   addArrayChannels={addArrayChannels}
                                   setColumns={setColumns}
                                   close={close}
                                   taskLoading={taskLoading}/>
        }

        {
            !TaskLoadingStore.taskLoadingList.hasModel &&
            <Loading/>
        }
        {
            TaskLoadingStore.taskLoadingList.hasModel &&
            TaskLoadingStore.taskLoadingList.Model!.map(x =>
                <TaskLoadingRowComponent key={x.id} model={x}
                                         repeatTask={repeatTask}
                                         open={open}/>)
        }
    </div>
});

export const CombinedComponent = () => {
    let [channel, setChannel] = useState('');

    const addChannels = (channels: string[]) => {
        if (channels.length === 0) return;
        if (channel === "") {
            setChannel(channels.join(splitChannel));
            return;
        }
        let oldChannels = channel.split(splitChannel).filter((x) => x !== '');
        let filteredChannels = channels.filter((x) => !oldChannels.includes(x));
        setChannel(prevState => prevState + splitChannel + filteredChannels.join(splitChannel));
    };

    return (
        <>
            <TaskLoadingCreateComponent channel={channel} setChannel={setChannel} />
            <TaskLoadingTableComponent addChannels={addChannels} />
        </>
    );
};
