import React, {useEffect} from "react";
import './settingsPage.scss'
import {SettingsStore} from "./SettingsStore";
import {Button, Input, PasswordInput, TextInput} from "@mantine/core";


export const SettingsPage = () => {
    let store = new SettingsStore();
    useEffect(() => {
        store.init();
    }, [])
    return<div className="settings-page">
        <div className="page">
            <form onSubmit={store.formChangeName.onSubmit((values, event) =>
                store.updateName(values.firstName, values.surName))}>
                <Input.Wrapper label="Введите Имя">
                    <TextInput placeholder="Введите Имя" {...store.formChangeName.getInputProps('firstName')}/>
                </Input.Wrapper>
                <Input.Wrapper label="Введите Фамилию">
                    <TextInput placeholder="Введите Фамилию" {...store.formChangeName.getInputProps('surName')}/>
                </Input.Wrapper>
                <Button
                    className="save-button"
                    variant="gradient"
                    type="submit"
                    loading={store.formChangeName.values.loading}
                    gradient={{from: 'blue', to: 'cyan', deg: 90}}>
                    Сохранить
                </Button>
            </form>
        </div>
        <div className="page">
            <form onSubmit={store.formChangePassword.onSubmit((values, event) =>
                store.updatePassword(values.oldPassword, values.newPassword))}>
                <Input.Wrapper label="Старый пароль">
                    <PasswordInput placeholder="Старый пароль" {...store.formChangePassword.getInputProps('oldPassword')}/>
                </Input.Wrapper>
                <Input.Wrapper label="Новый пароль" error={store.formChangePassword.errors["error"]}>
                    <PasswordInput placeholder="Новый пароль" {...store.formChangePassword.getInputProps('newPassword')}/>
                </Input.Wrapper>
                <Button
                    className="save-button"
                    variant="gradient"
                    type="submit"
                    loading={store.formChangePassword.values.loading}
                    gradient={{from: 'blue', to: 'cyan', deg: 90}}>
                    Изменить пароль
                </Button>
            </form>
        </div>
        {
            store.formEmailConfirmed.values.confirmedEmail === false &&
            <div className="page">
                <form onSubmit={store.formChangeEmail.onSubmit((values, event) =>
                    store.confirmEmail())}>
                    Подтвердить E-mail
                    <br/>
                    <Button
                        className="save-button"
                        variant="gradient"
                        type="submit"
                        loading={store.formEmailConfirmed.values.loading}
                        gradient={{from: 'blue', to: 'cyan', deg: 90}}>
                        Подтвердить
                    </Button>
                </form>
            </div>
        }
        <div className="page">
            <form onSubmit={store.formChangeEmail.onSubmit((values, event) =>
                store.updateEmail(values.newEmail))}>
                <Input.Wrapper label="Новый E-mail" error={store.formChangeEmail.errors["error"]}>
                    <TextInput placeholder="Новый" {...store.formChangeEmail.getInputProps('newEmail')}/>
                </Input.Wrapper>
                <Button
                    className="save-button"
                    variant="gradient"
                    type="submit"
                    loading={store.formChangeEmail.values.loading}
                    gradient={{from: 'blue', to: 'cyan', deg: 90}}>
                    Сменить E-mail
                </Button>
            </form>
        </div>
    </div>
}