import React, { useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import AuthStore from "../auth/store/AuthStore";
import { Menu, Button, rem, Group } from '@mantine/core';
import {
    IconClipboardList, IconHistory,
    IconSettings,
    IconTrash,
} from '@tabler/icons-react';
import './layout.scss';
import {observer} from "mobx-react";

export const Layout = observer(({ children }: { children: React.ReactNode }) => {
    const location = useLocation();

    useEffect(() => {
        const timer = setInterval(() => {
            if (AuthStore.isAuth){
                AuthStore.updateAccount();
            }
        }, 4000);
        return () => clearInterval(timer);
    }, []);
    if (!AuthStore.isAuth)
        return <></>

    return (
        <div className="layout-wrapper">
            <Group className="layout_page">
                <Group className="menu_group">
                    <Link to="/" className={`menu_button ${location.pathname === '/' ? 'active_button' : ''}`}>
                        <img src="/logo.png" style={{ width: '40px', height: '40px' }}  alt="logo"/>
                    </Link>
                    <Link to="https://telepars.ru/blog" className={`menu_button ${location.pathname === '/blog' ? 'active_button' : ''}`}>Блог</Link>
                    <Link to="/balls" className={`menu_button ${location.pathname === '/store' ? 'active_button' : ''}`}>Оплата</Link>
                    <Link to="/settings" className={`menu_button ${location.pathname === '/settings' ? 'active_button' : ''}`}>Настройка</Link>
                </Group>
                <Group className="right_group">
                    <Menu shadow="md" width={200}>
                        <Menu.Target>
                            <Button className="balls_button">{AuthStore.balls} Б</Button>
                        </Menu.Target>

                        <Menu.Dropdown>
                            <Menu.Label>Меню</Menu.Label>
                            <Menu.Item component={Link} to={"/"} leftSection={<IconClipboardList style={{ width: rem(14), height: rem(14) }} />}>
                                Задачи
                            </Menu.Item>
                            <Menu.Item component={Link} to={"/balls"} leftSection={<IconHistory style={{ width: rem(14), height: rem(14) }} />}>
                                Оплата
                            </Menu.Item>
                            <Menu.Item component={Link} to={"/settings"} leftSection={<IconSettings style={{ width: rem(14), height: rem(14) }} />}>
                                Настройка
                            </Menu.Item>
                            {
                                AuthStore.admin &&
                                <Menu.Item component={Link} to={"/promocodes"} leftSection={<IconSettings style={{ width: rem(14), height: rem(14) }} />}>
                                    Промокоды
                                </Menu.Item>
                            }
                            <Menu.Divider/>
                            <Menu.Item
                                color="red"
                                onClick={AuthStore.signOut}
                                leftSection={<IconTrash style={{width: rem(14), height: rem(14)}}/>}>
                                Выйти
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Group>
            <div className="layout-content">
                {children}
            </div>
        </div>
    );
})
