export const getErrors = (error: string | undefined | null) => {
    if (!error)
        return undefined;
    let errors = error?.split(',');
    let textErrors = '';
    for (let e in errors){
        textErrors += errorText(errors[e]) + ", ";
    }
    return textErrors;
}

export const errorText = (error: string) =>{
    if (error === "DuplicateUserName"){
        return "Логин уже занять";
    }
    if (error === "DuplicateEmail"){
        return "Email уже существует";
    }
    if (error === "LoginAlreadyAssociated"){
        return "Такой логин уже не существует";
    }
    if (error === "InvalidUserName"){
        return "Логин должен содержать только буквы и цифры";
    }
    if (error === "InvalidEmail"){
        return "Email '{email}' недействительный";
    }
    if (error === "PasswordTooShort"){
        return "Пароли должны быть как минимум 8 символов";
    }
    if (error === "PasswordRequiresDigit"){
        return "Пароли должны содержать хотя бы одну цифру ('0'-'9')";
    }
    if (error === "PasswordRequiresNonAlphanumeric"){
        return "Пароли должны содержать буквы и цифры";
    }
    if (error === "PasswordRequiresLower"){
        return "Пароли должны содержать строчную букву ('a'-'z')";
    }
    if (error === "PasswordRequiresUpper"){
        return "Пароли должны содержать заглавную букву ('A'-'Z')";
    }
    if (error === "PasswordMismatch"){
        return "Пароль не соответствует";
    }
    return error;
}