import {Button, Card, Group, Input, Text, TextInput} from "@mantine/core";
import AuthStore from "../store/AuthStore";
import React from "react";
import {isEmail, useForm} from "@mantine/form";

interface Props {
    clickAuth: () => void;
}

export const ForgotPasswordComponent = (props: Props) => {
    const formForgot = useForm({
        mode: 'controlled',
        initialValues: {
            email: '',
        },
        validate: {
            email: isEmail('Не проходит валидация по e-mail'),
        },
    });

    const forgotEmailClick = (email: string) => {
        AuthStore.forgotPassword({
            email: email
        });
    };

    return <Card className="auth-card" shadow="sm" padding="lg" radius="md" withBorder>
        <form onSubmit={formForgot.onSubmit((values, event) =>
            forgotEmailClick(values.email))}>
            <div className="title">Восстановить пароль</div>
            <Input.Wrapper label="Логин или e-mail">
                <TextInput placeholder="Логин или e-mail"  {...formForgot.getInputProps('email')}/>
            </Input.Wrapper>
            <Text onClick={props.clickAuth} className="link-page">Помните пароль?</Text>
            <Group justify="flex-end" mt="md">
                <Button
                    className="authorization-button"
                    variant="gradient"
                    type="submit"
                    loading={AuthStore.isAuth}
                    gradient={{from: 'blue', to: 'cyan', deg: 90}}>
                    Восстановить пароль
                </Button>
            </Group>
        </form>
    </Card>
}