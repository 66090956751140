import {Button, Card, Group, Input, PasswordInput, Text, TextInput} from "@mantine/core";
import AuthStore from "../store/AuthStore";
import React, {useState} from "react";
import {hasLength, isEmail, useForm} from "@mantine/form";
import {useDisclosure} from "@mantine/hooks";
import {getErrors} from "../../helper/Errors";

interface Props {
    clickAuth: () => void;
    confirmEmail: () => void;
}

export const RegistrationComponent = (props: Props) => {
    const [visible, {toggle}] = useDisclosure(false);
    const [error, setError] = useState<string | null>();
    const formRegistration = useForm({
        initialValues: {
            login: '',
            email: '',
            firstname: '',
            surname: '',
            password: '',
        },
        validate: {
            login: hasLength({min: 2, max: 30}, 'От 2 символов до 30 символов'),
            firstname: hasLength({min: 2, max: 30}, 'От 2 символов до 30 символов'),
            surname: hasLength({min: 2, max: 30}, 'От 2 символов до 30 символов'),
            email: isEmail('Не проходит валидация по e-mail'),
            password: hasLength({min: 8}, 'От 8 символов')
        },
    });



    const registrationSubmit = (email: string, firstname: string, login: string, password: string, surname: string,) => {
        AuthStore.registration({
            email: email, firstname: firstname, login: login, password: password, surname: surname
        }).then(x => {
            if (x.successful){
                props.confirmEmail()
            }
            if (!x.successful){
                setError(x.error)
            }
        });
    };

    return <Card className="auth-card" shadow="sm" padding="lg" radius="md" withBorder>
        <form onSubmit={formRegistration.onSubmit((values, event) =>
            registrationSubmit(values.email, values.firstname, values.login, values.password, values.surname))}>
            <div className="title">Регистрация</div>
            <Input.Wrapper label="Логин" error={getErrors(error)}>
                <Input name='login'  placeholder="Логин" {...formRegistration.getInputProps('login')}/>
            </Input.Wrapper>
            <Input.Wrapper label="E-mail">
                <TextInput name='email' placeholder="E-mail" {...formRegistration.getInputProps('email')}/>
            </Input.Wrapper>
            <Input.Wrapper label="Имя">
                <TextInput placeholder="Имя" {...formRegistration.getInputProps('firstname')}/>
            </Input.Wrapper>
            <Input.Wrapper label="Фамилия">
                <TextInput placeholder="Фамилия" {...formRegistration.getInputProps('surname')}/>
            </Input.Wrapper>
            <PasswordInput
                label="Пароль"
                {...formRegistration.getInputProps('password')}
                visible={visible}
                onVisibilityChange={toggle}/>
            <Text onClick={props.clickAuth} className="link-page">Уже зарегистрированы?</Text>
            <Group justify="flex-end" mt="md">
                <Button
                    className="authorization-button"
                    variant="gradient"
                    type="submit"
                    gradient={{from: 'blue', to: 'cyan', deg: 90}}
                    loading={AuthStore.isAuth}>
                    Регистрация
                </Button>
            </Group>
        </form>
    </Card>
}