import React from 'react';
import {ActionIcon, Modal, Tooltip} from "@mantine/core";
import {observer} from "mobx-react";
import {StateTask, TableColumnDto, TaskLoadingDto} from "../models/TaskLoadingDto";
import {MantineReactTable} from "mantine-react-table";
import {MRT_Localization_RU} from "mantine-react-table/locales/ru";
import {CustomButton} from "../../components/CustomButton";
import {downloadFile} from "../../requests/HelperRequest";
import {GetColumns} from "../TaskLoadingHelper";

interface Props {
    columns: TableColumnDto[] | undefined;
    setColumns: React.Dispatch<React.SetStateAction<TableColumnDto[] | undefined>>;
    taskLoading: TaskLoadingDto | undefined;
    addArrayChannels: (props: { index: number[] }) => void;
    modal: boolean;
    close: () => void;
}

const TaskLoadingModalTable = observer((props: Props) => {

    const loadingCSV = () => {// https://t.me/
        downloadFile('api/TaskLoading/csv/' + props.taskLoading?.id)
            .then(x => {
                if (x.ok) {
                    x.blob().then(blob => {
                        const url = window.URL.createObjectURL(new Blob([blob], {type : 'data:text/csv;'}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = props.taskLoading?.id + '_' + props.taskLoading?.name + '.csv';
                        link.style.position = 'absolute';
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    });
                }
            })
            .catch(() => {
                alert('запрос в обработке. Подождите');
            });
    };

    return (
        <Modal opened={props.modal} size={1600} onClose={props.close}>
            {props.taskLoading && <div style={{color: "red"}}>
                {props.taskLoading.errorMessage}
            </div>}
            {
                props.columns?.length === 0 && props.taskLoading?.state === StateTask.Finish &&
                <h3>
                    Ничего не нашли, попробуйте с другим каналом
                </h3>
            }
            {props.columns && props.columns.length !== 0 && (
                <MantineReactTable
                    initialState={{density: 'xs'}}
                    columns={GetColumns(props.columns, props.taskLoading!.type)}
                    data={props.columns}
                    enableSorting={true}
                    enableStickyHeader={true}
                    enableTableHead={true}
                    enableRowSelection={true}
                    enableColumnActions={false}
                    enableRowActions={false}
                    enableDensityToggle={true}
                    localization={MRT_Localization_RU}
                    mantinePaginationProps={{
                        rowsPerPageOptions: ['10', '50', '100', '500'],
                    }}
                    renderTopToolbarCustomActions={({table}) => (
                        <div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
                            <CustomButton
                                style={{marginRight: '10px'}}
                                onClick={() => {
                                    let res: number[] = [];
                                    for (const index in table.getState().rowSelection) {
                                        res.push(parseInt(index));
                                    }
                                    props.addArrayChannels({index: res});
                                }}>
                                Добавить в список каналов
                            </CustomButton>
                            <Tooltip label="Скачать CSV">
                                <ActionIcon onClick={loadingCSV} style={{ backgroundColor: 'transparent' }}>
                                    <img src="/28842.png" alt="Скачать CSV" style={{ width: '24px', height: '24px', backgroundColor: 'white', borderRadius: '4px' }}/>
                                </ActionIcon>
                            </Tooltip>
                        </div>
                    )}
                />
            )}
        </Modal>
    );
});

export default TaskLoadingModalTable;
